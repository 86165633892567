export const data = [
  {
    "subject": "Certificat",
    "actions":
      [
        {
          "action": "read"
        },
        {
          "action": "create"
        },
        {
          "action": "update"
        },
        {
          "action": "delete"
        }
      ]
  },
  {
    "subject": "Equipment",
    "actions":
      [
        {
          "action": "read"
        },
        {
          "action": "create"
        },
        {
          "action": "update"
        },
        {
          "action": "delete"
        }
      ]
  },
  {
    "subject": "Tag",
    "actions":
      [
        {
          "action": "read"
        },
        {
          "action": "create"
        },
        {
          "action": "update"
        },
        {
          "action": "delete"
        }
      ]
  },
  {
    "subject": "Domain",
    "actions":
      [
        {
          "action": "read"
        },
        {
          "action": "create"
        },
        {
          "action": "update"
        },
        {
          "action": "delete"
        }
      ]
  },
  {
    "subject": "Catalog",
    "actions":
      [
        {
          "action": "read"
        },
        {
          "action": "create"
        },
        {
          "action": "update"
        },
        {
          "action": "delete"
        }
      ]
  },
  {
    "subject": "Component",
    "actions":
      [
        {
          "action": "read"
        },
        {
          "action": "create"
        },
        {
          "action": "update"
        },
        {
          "action": "delete"
        }
      ]
  },
  {
    "subject": "Variant",
    "actions":
      [
        {
          "action": "read"
        },
        {
          "action": "create"
        },
        {
          "action": "update"
        },
        {
          "action": "delete"
        }
      ]
  },
  {
    "subject": "TechnicalProduct",
    "actions":
      [
        {
          "action": "read",
          "fields":
            [
              {
                "field": "VAT"
              },
              {
                "field": "customerTProductInfos"
              },
              {
                "field": "traderTProductInfos"
              }
            ]
        },
        {
          "action": "create"
        },
        {
          "action": "update",
          "fields":
            [
              {
                "field": "VAT"
              },
              {
                "field": "customerTProductInfos"
              },
              {
                "field": "traderTProductInfos"
              }
            ]
        },
        {
          "action": "delete"
        },
        {
          "action": "addTag"
        },
        {
          "action": "removeTag"
        },
        {
          "action": "addCertificat"
        },
        {
          "action": "removeCertificat"
        },
        {
          "action": "addEquipment"
        },
        {
          "action": "removeEquipment"
        },
        {
          "action": "addVariant"
        },
        {
          "action": "removeVariant"
        },
        {
          "action": "updateVariantQty"
        }
      ]
  },
  {
    "subject": "CommercialProduct",
    "actions":
      [
        {
          "action": "read",
          "fields":
            [
              {
                "field": "commercialInfo"
              },
              {
                "field": "seoInfo"
              },
              {
                "field": "photos"
              }
            ]
        },
        {
          "action": "create"
        },
        {
          "action": "update",
          "fields":
            [
              {
                "field": "commercialInfo"
              },
              {
                "field": "seoInfo"
              },
              {
                "field": "photos"
              }
            ]
        },
        {
          "action": "activate"
        },
        {
          "action": "archive"
        },
        {
          "action": "publishToCatalog"
        },
        {
          "action": "unPublishFromCatalog"
        }
      ]
  }
]
