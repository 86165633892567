import * as React from "react";
import {ReactNode} from "react";
import {Box} from '@mui/material';
import { styled } from '@mui/material/styles';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280
  }
}));

type Props = {
  children: NonNullable<ReactNode>;
}
export const Layout : React.FC<Props> = ({ children }) => {

  return (
    <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          {children}
        </Box>
    </DashboardLayoutRoot>
  );
};
