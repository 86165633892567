import React from "react";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {data} from "./data/roles"

import {
  Container,
  Stack,
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Switch,
} from "@mui/material";

// interface RoleI {
//   subject: string,
//   actions: ActionsI,
// }
//
// interface ActionsI {
//   action: string,
//   fields?: FieldsI,
// }
//
// interface FieldsI {
//   field: string,
// }
//

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const roles = [
  'Responsable catalogue',
  'Gestionnaire catalogue',
  'Gestionnaire d\'informations Produits',
  'Auditeur',
  'Utilisateur catalogue',
  'Utilisateur connecté',
  'Utilisateur non connecté',
  'Anonyme',
  'Administrateur BO Produit',
  'SuperAdmin',
];

function getStyles(role, roleName, theme) {
  return {
    fontWeight:
      roleName.indexOf(role) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function Roles(){
  const theme = useTheme();
  const [roleName, setRoleName] = React.useState([]);
  const label = { inputProps: { 'aria-label': 'Autorisé' } };
  const { t } = useTranslation();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRoleName(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  return (
    <Container sx={{ py:4 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Gestion des rôles
        </Typography>
      </Stack>
      <Card sx={{mb: 4, maxWidth: 900}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" padding="1rem 2rem">
          <Typography variant="h5">
            Choisir le rôle à éditer
          </Typography>
          <FormControl sx={{ m: 1, width: 500 }}>
            <InputLabel id="role-label">Rôle</InputLabel>
            <Select
              labelId="role-label"
              id="role"
              multiple
              value={roleName}
              onChange={handleChange}
              input={<OutlinedInput label="Rôle" />}
              MenuProps={MenuProps}
            >
              {roles.map((role) => (
                <MenuItem
                  key={role}
                  value={role}
                  style={getStyles(role, roleName, theme)}
                >
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Card>
      {data.map((role) => {
        return (
          <Card sx={{mb: 4, padding: 4, maxWidth: 900}} key={role.subject}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h5" gutterBottom>
                {t(`${role.subject}._subject`)}
              </Typography>
            </Stack>
            <TableContainer sx={{ minWidth: 600, maxWidth: 900}}>
              <Table>
                <TableBody>
                  {role.actions.map((action) => {
                    const setRowSpan = action.fields !== undefined ? action?.fields?.length : 0;
                    return (
                      <>
                        <TableRow
                          key={action.action}
                        >
                          <TableCell sx={{ fontSize: "16px"}} width="300" rowSpan={setRowSpan + 1}>
                            {t(`${role.subject}.${action.action}`)}
                          </TableCell>
                          <TableCell width="300"></TableCell>
                          <TableCell width="60" ><Switch {...label} defaultChecked color={"success"} /></TableCell>
                        </TableRow>
                        {action?.fields?.map((field) => {
                          return (
                            <TableRow key={field.field}>
                              <TableCell width="300"  sx={{ fontSize: "16px"}}  >
                                {t(`${role.subject}._fields.${field.field}`)}
                              </TableCell>
                              <TableCell width="60" ><Switch {...label} defaultChecked color={"success"}/></TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        );
      })}

    </Container>
  )
}

export default Roles;
