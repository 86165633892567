import React from 'react';
import {
  Switch,
  Route
} from "react-router-dom";
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import {Layout} from "./components/layout";
import Roles from './module/roles';

function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Layout>
        <Switch>
          <Route path="/" exact component={Roles} />
        </Switch>
      </Layout>
    </ThemeConfig>
  );
}

export default App;
